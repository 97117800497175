// Cache DOM elements
const DOM = {
    skeleton: document.getElementById("skeleton"),
    blogBox: document.getElementById("blogBox"),
    positionBox: document.getElementById("position_box"),
    applyCareer: document.getElementById("apply_career"),
    showBtn: document.getElementById("show_btn"),
    jobTitle: document.getElementById("designation_title"),
    experience: document.getElementById("experience"),
    introduction: document.getElementById("introduction"),
    jobSkill: document.getElementById("job_skill"),
    jobDescription: document.getElementById("job_description")
};

// Blog Posts Section
const getData = () => {
    fetch(`${process.env.APIURL}/blog/api/posts`)
        .then(response => response.json())
        .then(data => {
            if (DOM.skeleton) DOM.skeleton.remove();
            if (!DOM.blogBox) return;

            const html = data.data.slice(0, 6).map(post => {
                const date = new Date(post.created_at);
                return `
                    <div class="swiper-slide mb-2 scale-100 hover:scale-105 transition-all duration-500 hover:shadow-md">
                        <div class="mb-2">
                            <a href="${post.url}" target="_blank">
                                <div class="slider_bg" style="background: url(${post.dp_url});"></div>
                            </a>
                        </div>
                        <div class="p-2">
                            <div class="flex gap-4 items-center mb-2">
                                <div class="border-r pr-4 flex items-center">
                                    <a href="${post.url}" class="Gilroy-Medium text-sm text-violet-900 capitalize">${post.category}</a>
                                </div>
                                <div>
                                    <a href="${post.url}" target="_blank" class="flex gap-2 items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#5C3EB3" class="w-6 h-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                        </svg>
                                        <p class="Gilroy-Medium text-sm text-violet-900">${post.reading_time}</p>
                                    </a>
                                </div>
                            </div>
                            <p class="mb-2 Gilroy-Bold heading_4">${post.title.length > 30 ? post.title.slice(0, 30) + "..." : post.title}</p>
                            <p class="mb-2 Gilroy-Regular">${post.description.length > 100 ? post.description.slice(0, 100) + "..." : post.description}</p>
                            <div class="flex justify-between items-center">
                                <div>
                                    <a href="${post.url}" target="_blank" class="flex gap-2 items-center">
                                        <img src="${post.author.dp_url}" alt="${post.title.slice(0, 50)}" class="blogsImage w-10 h-10 rounded-full border border-slate-300 object-fill"/>
                                        <p class="Gilroy-Medium text-sm">${post.author.name}</p>
                                    </a>
                                </div>
                                <div>
                                    <p class="Gilroy-Medium text-sm">${date.toLocaleDateString()}</p>
                                </div>
                            </div>
                        </div>
                    </div>`;
            }).join('');
            
            DOM.blogBox.innerHTML = html;
        })
        .catch(err => console.log(err));
};

// Career Section
let emptyArr = [];
let limit = 3;
let offset = 0;

const career = (lim, off) => {
    fetch(`${process.env.APIURL}/blog/api/open-positions?limit=${lim}&offset=${off}`)
        .then(response => response.json())
        .then(data => {
            offset += 3;
            const results = data?.data?.results || [];
            emptyArr.push(...results);
            
            if (DOM.positionBox && results.length) {
                const fragment = document.createDocumentFragment();
                results.forEach(job => {
                    const date = new Intl.DateTimeFormat("en-US").format(new Date(job.updated_at));
                    const node = document.createElement("div");
                    node.style.cursor = "pointer";
                    node.classList.add("bg-violet");
                    node.id = job.id;
                    node.innerHTML = `
                        <div class="col-span-1 w-full py-8 lg:px-14 px-4 border-b transition duration-150 ease-out hover:ease-in relative all_vaccancy">
                            <div class="flex items-center justify-between">
                                <div>
                                    <p class="xl:text-base text-sm Gilroy-semiBold opacity-80">${date}</p>
                                    <h3 class="xl:text-2xl text-lg Gilroy-semiBold capitalize text-white">${job.title}</h3>
                                    <p class="mt-4 lg:mt-0">
                                        <span class="text-base Gilroy-semiBold opacity-80">
                                            ${job.max_experience !== "None" 
                                                ? `${job.min_experience} - ${job.max_experience} Year Experience`
                                                : `${job.min_experience} + Year Experience`}
                                        </span>
                                    </p>
                                </div>
                                <div class="text-base capitalize Gilroy-Medium border-white border-1 border px-5 py-2">view detail</div>
                            </div>
                        </div>`;
                    fragment.appendChild(node);
                });
                DOM.positionBox.appendChild(fragment);
                
                // Add event listeners after DOM insertion
                results.forEach(job => initializeEvent(job.id));
            }

            if (DOM.applyCareer) {
                DOM.applyCareer.classList.remove("block");
                DOM.applyCareer.classList.add("hidden");
            }

            if (DOM.showBtn && (emptyArr.length === 0 || emptyArr.length === data?.data?.count)) {
                DOM.showBtn.classList.add("hidden");
            }
        })
        .catch(err => console.log(err));
};

// Event Handler - Simplified to ensure clicks work
function initializeEvent(id) {
    const viewEle = document.getElementById(id);
    if (viewEle) {
        // Remove any existing listeners to prevent duplicates
        viewEle.removeEventListener("click", viewEle.onclick);
        
        viewEle.onclick = () => {
            const url = new URL(`${process.env.APIURL}/apply-career`);
            url.searchParams.append("id", id);
            window.open(url.href, "_self");
        };
    }
}

// Portfolio Section
function getPortfolioById() {
    const koopId = window.location.href.split("=")[1];
    if (koopId) getPositionById(koopId);
}

function getPositionById(id) {
    fetch(`${process.env.APIURL}/blog/api/open-positions/${id}`)
        .then(response => response.json())
        .then(data => {
            if (!data.data) return;
            
            const { data: portfolio } = data;
            const exp = portfolio.max_experience !== null && portfolio.max_experience !== "None"
                ? `${portfolio.min_experience} - ${portfolio.max_experience} Year Experience`
                : `${portfolio.min_experience} + Year Experience`;

            if (DOM.jobTitle) DOM.jobTitle.innerHTML = portfolio.title;
            if (DOM.experience) DOM.experience.innerHTML = exp;
            if (DOM.introduction) DOM.introduction.innerHTML = portfolio.description;

            if (DOM.jobSkill) {
                const skillsFragment = document.createDocumentFragment();
                portfolio.desired_skillsets.forEach(skill => {
                    const li = document.createElement("li");
                    li.className = "listTpyo";
                    li.textContent = skill;
                    skillsFragment.appendChild(li);
                });
                DOM.jobSkill.innerHTML = '';
                DOM.jobSkill.appendChild(skillsFragment);
            }

            if (DOM.jobDescription) {
                const descFragment = document.createDocumentFragment();
                portfolio.roles_responsibilities.forEach(role => {
                    const li = document.createElement("li");
                    li.className = "listTpyo";
                    li.textContent = role;
                    descFragment.appendChild(li);
                });
                DOM.jobDescription.innerHTML = '';
                DOM.jobDescription.appendChild(descFragment);
            }
        })
        .catch(err => console.log(err));
}

// Initialize
getData();
career(limit, offset);
getPortfolioById();

// Exports
window.getData = getData;
window.career = career;
window.BTNN = () => career(limit, offset);
window.initializeEvent = initializeEvent;
window.getPortfolioById = getPortfolioById;
window.getPositionById = getPositionById;